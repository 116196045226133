import React from 'react'
import { useFetchEntreprise } from '../../../action/api/employeur/EmployeurAction'
import { getAndCheckLocalStorage, setWithExpiration } from '../../../utlis/storage/localvalueFunction'
import { dureeDeVie, localvalue } from '../../../utlis/storage/localvalue'
import { packsItemsList, statusPACKS } from '../../../utlis/config'
import { toast } from 'react-toastify'
import { routing } from '../../../utlis/routing'

const CandidatCard = ({ item, entreprise }) => {
    var idEntreprise = getAndCheckLocalStorage(localvalue.recruteurDetailID);




    return (
        <div class="w-full  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div class="flex justify-end px-4 pt-4">
                <button id="dropdownButton" data-dropdown-toggle="dropdown" class="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5" type="button">
                    <span class="sr-only">Open dropdown</span>
                    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                        <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                    </svg>
                </button>

                <div id="dropdown" class="z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                    <ul class="py-2" aria-labelledby="dropdownButton">
                        <li>
                            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Edit</a>
                        </li>
                        <li>
                            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Export Data</a>
                        </li>
                        <li>
                            <a href="#" class="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="flex flex-col items-center pb-5">
                <img class="w-10 h-10 mb-3 rounded-full shadow-lg"
                    src={item.coverPicture}
                    alt="Bonnie image" />
                <h5 class="mb-1 text-sm font-medium text-gray-900 dark:text-white">{`${item.firstname} ${item.lastname}`}</h5>
                <span class="text-xs text-gray-500 dark:text-gray-400">{item.title_post}</span>
                <div class="flex mt-4 space-x-3 md:mt-6">
                    <div
                        type='button'
                        onClick={() => {
                            if (entreprise &&

                                entreprise.account.pack == statusPACKS[0] ||
                                entreprise.account.pack == statusPACKS[1] ||
                                entreprise.account.pack == statusPACKS[2]
                            ) {
                                setWithExpiration(localvalue.candidatDetailID, item._id, dureeDeVie);
                                window.location.href = `/${routing.candidat_details_view}`
                            } else {
                                toast.error("Votre pack ne vous authorise pas !")
                            }
                        }} class="cursor-pointer inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        voire profile
                    </div>
                    {
                        /*<a href="#" class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700">Message</a> */
                    }
                </div>
            </div>
        </div>
    )
}

export default CandidatCard
