import React from 'react';
import BarnerHome from '../components/home/BarnerHome';
import CategoryHome from '../components/home/CategoryHome';
import JobListHome from '../components/home/JobListHome';
import HomeProcess from '../components/home/HomeProcess';
import HomeStart from '../components/home/HomeStart';
import HomeTestimonial from '../components/home/HomeTestimonial';
import HomeBlog from '../components/home/HomeBlog';
import HomePartenaires from '../components/home/HomePartenaires';
import HomeSubscrible from '../components/home/HomeSubscrible';
import HomeSolgan from '../components/home/HomeSolgan';
import HomePacks from '../components/home/HomePacks';
import HomeBourses from '../components/home/HomeBourses';
import TrainingSection from '../components/home/TrainingSection';
import Testimonials from '../components/home/Testimonials';

const HomePage = () => {
  return (
    <div className="main-content">
      <div className="page-content">


        <BarnerHome />
        <HomeSolgan/>
        <HomeBourses/>
        <TrainingSection/>
        <Testimonials/>
        <HomePacks/>
        
        {
          /*<CategoryHome/> */
        }

        {/*<HomeBlog/> */}
        <JobListHome/>


        <HomeProcess/>
        <HomeStart/>
        
        {
          /*
          <HomeTestimonial/>
          <HomeBlog/>
        <HomePartenaires/>
        <HomeSubscrible/> */
        }


      </div>
    </div>
  )
}

export default HomePage;