import React from 'react';
import { useNavigate } from 'react-router-dom';
import './JobCard.css';
import { setWithExpiration , getAndCheckLocalStorage } from '../../utlis/storage/localvalueFunction';
import { dureeDeVie, localvalue } from '../../utlis/storage/localvalue';
import { routing } from '../../utlis/routing';


const JobCard = ({ data }) => {
    const navigate = useNavigate();

    return (
        <div className="job-card cursor-pointer" onClick={()=>{
            setWithExpiration(localvalue.JobID, data._id, dureeDeVie);
                            navigate(`/${routing.job_details}`, { state: { data } })
        }}>
            {/*<div className="job-image-wrapper">
                <img src={data.coverPicture} alt={data.title} className="job-image object-cover" />
            </div> */}
            <div className="job-content">
                <h5 className="job-title text-gray-700">{data.title}</h5>
                <p className="job-company text-gray-600">{data.company}</p>
                {data.addresse && (
                    <p className="job-address">
                        <i className="mdi mdi-map-marker text-gray-700"></i> {data.addresse}
                    </p>
                )}
                <p className="job-contract">{data.typeContrat}</p>
                <div className="job-details-link">
                    <a className="text-gray-700"
                        href={`#`}
                        onClick={() => {
                            setWithExpiration(localvalue.JobID, data._id, dureeDeVie);
                            navigate(`/${routing.job_details}`, { state: { data } })}
                        }
                    >
                        Voir Détails
                    </a>
                </div>
            </div>
        </div>
    );
};

export default JobCard;
