





// Secteur d'activité pour  les entreprise
export const secteursActivite = [
  { label: "Technologie de l'information", value: "technologie_information" },
  { label: "Développement informatique", value: "developpement_informatique" },
  { label: "Construction", value: "construction" },
  { label: "Énergie renouvelable", value: "energie_renouvelable" },
  { label: "Comptabilité et audit", value: "comptabilite_audit" },
  { label: "Banque et finance", value: "banque_finance" },
  { label: "Transport et logistique", value: "transport_logistique" },
  { label: "Industrie manufacturière", value: "industrie_manufacturiere" },
  { label: "Télécommunications", value: "telecommunications" },
  { label: "Informatique en nuage", value: "informatique_nuage" },
  { label: "Industrie minière", value: "industrie_miniere" },
  { label: "Santé et sciences de la vie", value: "sante_sciences_vie" },
  { label: "Aérospatiale et défense", value: "aerospatiale_defense" },
  { label: "Biotechnologie", value: "biotechnologie" },
  { label: "Immobilier", value: "immobilier" },
  { label: "Éducation et formation", value: "education_formation" },
  { label: "Marketing et publicité", value: "marketing_publicite" },
  { label: "Commerce de détail", value: "commerce_detail" },
  { label: "Hôtellerie et tourisme", value: "hotellerie_tourisme" },
  { label: "Agriculture", value: "agriculture" },
  { label: "Divertissement", value: "divertissement" },
  { label: "Assurance", value: "assurance" },
  { label: "Consultation en gestion", value: "consultation_gestion" },
  { label: "Ingénierie", value: "ingenierie" },
  { label: "Automobile", value: "automobile" },
  { label: "Services environnementaux", value: "services_environnementaux" },
  { label: "Médias", value: "medias" },
  { label: "Sécurité informatique", value: "securite_informatique" },
  { label: "Pétrole et gaz", value: "petrole_gaz" },
  { label: "Services financiers", value: "services_financiers" },
  { label: "Santé mentale", value: "sante_mentale" },
  { label: "Architecture", value: "architecture" },
  { label: "Commerce électronique", value: "commerce_electronique" },
  { label: "Jeux vidéo", value: "jeux_video" },
  { label: "Santé numérique", value: "sante_numerique" },
  { label: "Agriculture de précision", value: "agriculture_precision" },
  { label: "Aéroportuaire", value: "aeroportuaire" },
  { label: "Systèmes embarqués", value: "systemes_embarques" },
  { label: "Intelligence artificielle", value: "intelligence_artificielle" },
  { label: "Consultation juridique", value: "consultation_juridique" },
  { label: "Spatial", value: "spatial" },
  { label: "Industrie pharmaceutique", value: "industrie_pharmaceutique" },
  { label: "Robotique", value: "robotique" },
  { label: "Électricité et électronique", value: "electricite_electronique" },
  { label: "Réseaux sociaux", value: "reseaux_sociaux" },
  { label: "Transport en commun", value: "transport_en_commun" },
  { label: "Jeux de hasard", value: "jeux_de_hasard" },
  { label: "Cryptomonnaies", value: "cryptomonnaies" },
  { label: "Technologie de la chaîne de blocs", value: "technologie_chaine_blocs" },
  { label: "E-commerce", value: "e_commerce" },
  { label: "Industrie aéronautique", value: "industrie_aeronautique" },
  { label: "Marketing", value: "marketing" },
  { label: "Santé", value: "sante" },
  { label: "Consulting", value: "consulting" },
];


export const employers = [
  "1-10",
  "10-25",
  "25-50",
  "50-100",
  "100-250",
  "250-500",
  "500-750",
  "750-1000",
  "1000-3000",
  "3000-5000",
  "5000-10000",
  "10000-50000",
  "50000-100000",
  "100000-plus.",
]


export const existence_entreprise = [
  "0-1",
  "1-2",
  "2-3",
  "3-4",
  "5-10",
  "10-15",
  "15-20",
  "20-25",
  "25-50",
  "50-plus",
  
]


export const secteursUrgentces = [
  { label: "Alternative", value: "Alternative" },
  { label: "Urgent", value: "Urgente" },
  { label: "Permanence", value: "Permanence" },
];


