export const optionPays = [
    { label: "Algérie", value: "Algeria" },
    { label: "Angola", value: "Angola" },
    { label: "Bénin", value: "Benin" },
    { label: "Botswana", value: "Botswana" },
    { label: "Burkina Faso", value: "Burkina Faso" },
    { label: "Burundi", value: "Burundi" },
    { label: "Cameroun", value: "Cameroon" },
    { label: "Cap-Vert", value: "Cape Verde" },
    { label: "République centrafricaine", value: "Central African Republic" },
    { label: "Tchad", value: "Chad" },
    { label: "Comores", value: "Comoros" },
    { label: "République du Congo", value: "Congo (Republic of the)" },
    { label: "République démocratique du Congo", value: "Congo (Democratic Republic of the)" },
    { label: "Côte d'Ivoire", value: "Cote d'Ivoire" },
    { label: "Djibouti", value: "Djibouti" },
    { label: "Égypte", value: "Egypt" },
    { label: "Guinée équatoriale", value: "Equatorial Guinea" },
    { label: "Érythrée", value: "Eritrea" },
    { label: "Éthiopie", value: "Ethiopia" },
    { label: "Gabon", value: "Gabon" },
    { label: "Gambie", value: "Gambia" },
    { label: "Ghana", value: "Ghana" },
    { label: "Guinée", value: "Guinea" },
    { label: "Guinée-Bissau", value: "Guinea-Bissau" },
    { label: "Kenya", value: "Kenya" },
    { label: "Lesotho", value: "Lesotho" },
    { label: "Liberia", value: "Liberia" },
    { label: "Libye", value: "Libya" },
    { label: "Madagascar", value: "Madagascar" },
    { label: "Malawi", value: "Malawi" },
    { label: "Mali", value: "Mali" },
    { label: "Mauritanie", value: "Mauritania" },
    { label: "Maurice", value: "Mauritius" },
    { label: "Maroc", value: "Morocco" },
    { label: "Mozambique", value: "Mozambique" },
    { label: "Namibie", value: "Namibia" },
    { label: "Niger", value: "Niger" },
    { label: "Nigeria", value: "Nigeria" },
    { label: "Rwanda", value: "Rwanda" },
    { label: "São Tomé et Príncipe", value: "Sao Tome and Principe" },
    { label: "Sénégal", value: "Senegal" },
    { label: "Seychelles", value: "Seychelles" }
];
  


export const typeContrats = [
    {value:"CDD",label:"CDD"},
    {value:"CDI",label:"CDI"},
    {value:"STAGE",label:"STAGE"},
    {value:"ALTERNANCE",label:"ALTERNANCE"},
]



export const areaPostList = [
    "Technologie",
    "Science",
    "Voyage",
    "Santé et bien-être",
    "Mode",
    "Cuisine et recettes",
    "Sport et fitness",
    "Éducation",
    "Finance personnelle",
    "Automobile",
    "Art et créativité",
    "Musique",
    "Cinéma et divertissement",
    "Environnement et durabilité",
    "Politique",
    "Histoire",
    "Psychologie",
    "Relations et amour",
    "Parentalité",
    "Animaux de compagnie",
    "Photographie",
    "Actualités et événements actuels",
    "Science-fiction et fantasy",
    "Développement personnel",
    "Voyages culinaires",
    "Actualités technologiques",
    "Astuces et tutoriels",
    "Bricolage et projets maison",
    "Livres et littérature",
    "Spiritualité et méditation"
  ];
  




  export const categoriesDeBourses = [
    "Bourses d'études en ingénierie",
    "Bourses d'études en informatique",
    "Bourses d'études en sciences de la santé",
    "Bourses d'études en sciences humaines",
    "Bourses d'études en sciences sociales",
    "Bourses d'études en sciences naturelles",
    "Bourses d'études en mathématiques",
    "Bourses d'études en arts et en lettres",
    "Bourses d'études en commerce et en gestion",
    "Bourses d'études en sciences politiques",
    "Bourses d'études en psychologie",
    "Bourses d'études en éducation",
    "Bourses d'études en musique et en arts de la scène",
    "Bourses d'études en design et en arts visuels",
    "Bourses d'études en environnement et en durabilité",
    "Bourses d'études en génie civil",
    "Bourses d'études en médecine et en soins de santé",
    "Bourses d'études en droit",
    "Bourses d'études pour les femmes en STEM",
    "Bourses d'études pour les étudiants internationaux",
    "Bourses d'études pour les étudiants en situation de handicap",
    "Bourses d'études pour les minorités ethniques et raciales",
    "Bourses d'études pour les vétérans et les membres des forces armées",
    "Bourses d'études pour les étudiants en première génération",
    "Bourses d'études pour les étudiants en études supérieures",
  ];
  


  export const areaFormationOptions = [
    { value: 'Informatique', label: 'Informatique' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Finance', label: 'Finance' },
    { value: 'Langues', label: 'Langues' },
    { value: 'Développement personnel', label: 'Développement personnel' },
    { value: 'Sciences', label: 'Sciences' },
    { value: 'Art et design', label: 'Art et design' },
    { value: 'Musique', label: 'Musique' },
    { value: 'Cuisine et gastronomie', label: 'Cuisine et gastronomie' },
    { value: 'Sport et fitness', label: 'Sport et fitness' },
    { value: 'Santé et bien-être', label: 'Santé et bien-être' },
    { value: 'Photographie', label: 'Photographie' },
    { value: 'Mode et beauté', label: 'Mode et beauté' },
    { value: 'Voyages et tourisme', label: 'Voyages et tourisme' },
    { value: 'Environnement', label: 'Environnement' },
    { value: 'Droit', label: 'Droit' },
    { value: 'Histoire', label: 'Histoire' },
    { value: 'Psychologie', label: 'Psychologie' },
    { value: 'Éducation', label: 'Éducation' },
    { value: 'Ingénierie', label: 'Ingénierie' },
    { value: 'Architecture', label: 'Architecture' },
    { value: 'Marketing digital', label: 'Marketing digital' },
    { value: 'E-commerce', label: 'E-commerce' },
    { value: 'Gestion de projet', label: 'Gestion de projet' },
    { value: 'Leadership', label: 'Leadership' },
    { value: 'Communication', label: 'Communication' },
    { value: 'Finance personnelle', label: 'Finance personnelle' },
    { value: 'Art numérique', label: 'Art numérique' },
    { value: 'Théâtre', label: 'Théâtre' },
    { value: 'Danse', label: 'Danse' },
    { value: 'Cinéma', label: 'Cinéma' },
    { value: 'Journalisme', label: 'Journalisme' },
    { value: 'Médias sociaux', label: 'Médias sociaux' },
    { value: 'Développement web', label: 'Développement web' },
    { value: 'Intelligence artificielle', label: 'Intelligence artificielle' },
    { value: 'Robotique', label: 'Robotique' },
    { value: 'Sciences des données', label: 'Sciences des données' },
    { value: 'Bioinformatique', label: 'Bioinformatique' },
    { value: 'Médecine', label: 'Médecine' },
    { value: 'Nutrition', label: 'Nutrition' },
    { value: 'Yoga', label: 'Yoga' },
    { value: 'Méditation', label: 'Méditation' },
    { value: 'Pilates', label: 'Pilates' },
  ];
  