

export const years_experience_school = [
  "0 - 1",
  "1 - 2",
  "2 - 3",
  "3 - 4",
  "4 - 5",
  "5 - 6",
  "6 - 7",
  "7 - 8",
  "8 - 9",
  "9 - 10",
];


export const salaires_School = [
  "5,000-100,000",
  "100,000-200,000",
  "200,000-300,000",
  "300,000-400,000",
  "400,000-500,000",
  "500,000-600,000",
  "700,000-800,000",
  "800,000-900,000",
  "900,000-1,000,000",
  "1,000,000-10,000,000",
  "10,000,000-100,000,000",
  "10,000,0000-100,000,000",
  "100,000,0000-1,000,000,000",
];




export const level_School = [
  { label: "Sans diplôme", value: "sans-diplome" },
  { label: "Brevet des collèges", value: "brevet" },
  { label: "CAP / BEP", value: "cap-bep" },
  { label: "Bac", value: "bac" },
  { label: "Bac +2", value: "bac2" },
  { label: "Bac +3 / Licence", value: "licence" },
  { label: "Bac +4 / Master 1", value: "master1" },
  { label: "Bac +5 / Master 2", value: "master2" },
  { label: "Doctorat", value: "doctorat" }

];


export const competences = [
  { label: "Programmation", value: "programmation" },
  { value: 'python', label: 'Python' },
  { value: 'php', label: 'PHP' },
  { value: 'javascript', label: 'JavaScript' },
  { value: 'java', label: 'Java' },
  { value: 'csharp', label: 'C#' },
  { value: 'ruby', label: 'Ruby' },
  { value: 'html', label: 'HTML' },
  { value: 'css', label: 'CSS' },
  { value: 'sql', label: 'SQL' },
  { value: 'swift', label: 'Swift' },
  { value: 'typescript', label: 'TypeScript' },
  { value: 'go', label: 'Go' },
  { value: 'rust', label: 'Rust' },
  { value: 'kotlin', label: 'Kotlin' },
  { value: 'dart', label: 'Dart' },
  { label: "Design graphique", value: "design_graphique" },
  { value: 'graphisme', label: 'Graphisme' },
  { value: 'designWeb', label: 'Design Web' },
  { value: 'branding', label: 'Branding' },
  { value: 'marketingDigital', label: 'Marketing Digital' },
  { value: 'illustration', label: 'Illustration' },
  { value: 'strategieMarketing', label: 'Stratégie Marketing' },
  { value: 'gestionProjet', label: 'Gestion de Projet' },
  { value: 'communicationVisuelle', label: 'Communication Visuelle' },
  { value: 'analyseMarche', label: 'Analyse de Marché' },
  { value: 'publicite', label: 'Publicité' },
  { label: "Marketing", value: "marketing" },
  { label: "Rédaction de contenu", value: "redaction_contenu" },
  { label: "Gestion de projet", value: "gestion_projet" },
  { label: "Analyse de données", value: "analyse_donnees" },
  { label: "Communication", value: "communication" },
  { label: "Vente", value: "vente" },
  { label: "Relation client", value: "relation_client" },
  { label: "Développement web", value: "developpement_web" },
  { label: "Administration système", value: "administration_systeme" },
  { label: "Réseaux et sécurité", value: "reseaux_securite" },
  { label: "Gestion financière", value: "gestion_financiere" },
  { label: "Ressources humaines", value: "ressources_humaines" },
  { value: 'comptabiliteGenerale', label: 'Comptabilité Générale' },
  { value: 'fiscalite', label: 'Fiscalité' },
  { value: 'audit', label: 'Audit' },
  { value: 'gestionBudgetaire', label: 'Gestion Budgétaire' },
  { value: 'analyseFinanciere', label: 'Analyse Financière' },
  { value: 'gestionTresorerie', label: 'Gestion de Trésorerie' },
  { value: 'rapportsFinanciers', label: 'Rapports Financiers' },
  { value: 'gestionComptesClients', label: 'Gestion des Comptes Clients' },
  { value: 'gestionComptesFournisseurs', label: 'Gestion des Comptes Fournisseurs' },
  { value: 'logicielsComptabilite', label: 'Logiciels de Comptabilité' },
  { label: "Langues étrangères", value: "langues_etrangeres" },
  { label: "Service client", value: "service_client" },
  { label: "Développement mobile", value: "developpement_mobile" },
  { label: "Analyse fonctionnelle", value: "analyse_fonctionnelle" },
  { label: "Architecture logicielle", value: "architecture_logicielle" },
  { label: "UX/UI design", value: "ux_ui_design" },
  { label: "Management", value: "management" },
  { label: "Audit et conseil", value: "audit_conseil" },
  { label: "Logistique et supply chain", value: "logistique_supply_chain" },
  { label: "Santé et sécurité au travail", value: "sante_securite_travail" },
  { label: "Enseignement", value: "enseignement" },
  { label: "Développement durable", value: "developpement_durable" },
  { label: "Journalisme", value: "journalisme" },
  { label: "Traduction", value: "traduction" },
  { label: "Design industriel", value: "design_industriel" },
  { label: "Art et culture", value: "art_culture" },
];



export const languages_school = [
  { value: "Anglais", label: "Anglais" },
  { value: "Français", label: "Français" },
  { value: "Espagnol", label: "Espagnol" },
  { value: "Allemand", label: "Allemand" },
  { value: "Italien", label: "Italien" },
  { value: "Arabe", label: "Arabe" },
  { value: "Chinois", label: "Chinois" },
  { value: "Japonais", label: "Japonais" },
  { value: "Russe", label: "Russe" },
  { value: "Portugais", label: "Portugais" },
  { value: "Coréen", label: "Coréen" },
  { value: "Turc", label: "Turc" },
  { value: "Hindi", label: "Hindi" },
  { value: "Swahili", label: "Swahili" },
  { value: "Néerlandais", label: "Néerlandais" },
  { value: "Polonais", label: "Polonais" },
  { value: "Suédois", label: "Suédois" },
  { value: "Danois", label: "Danois" },
  { value: "Norvégien", label: "Norvégien" },
  { value: "Finnois", label: "Finnois" },
  { value: "Islandais", label: "Islandais" },
  { value: "Grec", label: "Grec" },
  { value: "Latin", label: "Latin" },
  { value: "Sanskrit", label: "Sanskrit" },
];





export const candidatsChoices = [
  {
    _id: "1",
    name: "Bly Bi Gohi Aymar",
    active: true,
    coverPicture: "https://img.freepik.com/photos-premium/homme-etudiant-afro-americain-fond-jaune-isole-lunettes-heureux_1368-222691.jpg?w=900",
    profession: "Développeur full stack, Animateur, Modélisateur 3D",
    description: "Je suis vraiment content de cette plateforme. J'ai eu une meilleure influence grâce à cela dans ma filière."
  },
  {
    _id: "2",
    name: "Alicia Touré",
    active: true,
    coverPicture: "https://images.pexels.com/photos/1858175/pexels-photo-1858175.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    profession: "Étudiante en Informatique et Marketing",
    description: "Je suis vraiment contente de cette plateforme. J'ai eu une meilleure influence grâce à cela dans ma filière."
  },
  {
    _id: "3",
    name: "Cédric",
    active: true,
    coverPicture: "https://img.freepik.com/photos-gratuite/etudiant-positif-peau-foncee-porte-dossiers-livre-pointe-expression-joyeuse-cote-sourire-pleines-dents_273609-23704.jpg?w=900&t=st=1693235710~exp=1693236310~hmac=2afd47b244941ca069e099779258dc77df9a96f3aedddf1511fdfd8d8e8c5479",
    profession: "Étudiant en Informatique et Marketing",
    description: "Je suis vraiment content de cette plateforme. J'ai eu une meilleure influence grâce à cela dans ma filière."
  }
];
