

export const SEND_REQUEST = "SEND_REQUEST";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_FAILURE = "REQUEST_FAILURE";



// Pour les offres d'emploi
export const FETCH_SUCCESS_OFFRES_EMPLOIS = "FETCH_SUCCESS_OFFRES_EMPLOIS";
export const FETCH_FAILED_OFFRES_EMPLOIS = "FETCH_FAILED_OFFRES_EMPLOIS";
export const FETCH_SEND_OFFRES_EMPLOIS = "FETCH_SEND_OFFRES_EMPLOIS";

// L'offre d'emplois en qustion
export const FETCH_SUCCESS_OFFRE_EMPLOI = "FETCH_SUCCESS_OFFRE_EMPLOI";
export const FETCH_FAILED_OFFRE_EMPLOI = "FETCH_FAILED_OFFRE_EMPLOI";
export const FETCH_SEND_OFFRE_EMPLOI = "FETCH_SEND_OFFRE_EMPLOI";

// Pour les messages
export const FETCH_SUCCESS_MESSAGES = "FETCH_SUCCESS_MESSAGES";
export const FETCH_FAILED_MESSAGES = "FETCH_FAILED_MESSAGES";
export const FETCH_SEND_MESSAGES = "FETCH_SEND_MESSAGES";

// Pour les candidatures
export const FETCH_SUCCESS_CANDIDATURES = "FETCH_SUCCESS_CANDIDATURES";
export const FETCH_FAILED_CANDIDATURES = "FETCH_FAILED_CANDIDATURES";
export const FETCH_SEND_CANDIDATURES = "FETCH_SEND_CANDIDATURES";

// Pour les profils des persones
export const FETCH_SUCCESS_PROFILES = "FETCH_SUCCESS_PROFILES";
export const FETCH_FAILED_PROFILES = "FETCH_FAILED_PROFILES";
export const FETCH_SEND_PROFILES = "FETCH_SEND_PROFILES";

// Profiles des entreprise
export const FETCH_SUCCESS_RECRUTERUR_PROFILES = "FETCH_SUCCESS_RECRUTERUR_PROFILES";
export const FETCH_FAILED_RECRUTERUR_PROFILES = "FETCH_FAILED_RECRUTERUR_PROFILES";
export const FETCH_SEND_RECRUTERUR_PROFILES = "FETCH_SEND_RECRUTERUR_PROFILES";


// Profile la personne connecté
export const FETCH_SUCCESS_PROFILE = "FETCH_SUCCESS_PROFILE";
export const FETCH_FAILED_PROFILE = "FETCH_FAILED_PROFILE";
export const FETCH_SEND_PROFILE = "FETCH_SEND_PROFILE";

// Pour les posts
export const FETCH_SUCCESS_POSTS = "FETCH_SUCCESS_POSTS";
export const FETCH_FAILED_POSTS = "FETCH_FAILED_POSTS";
export const FETCH_SEND_POSTS = "FETCH_SEND_POSTS";

// Pour les commentaires des postes
export const FETCH_SUCCESS_COMMENTS = "FETCH_SUCCESS_COMMENTS";
export const FETCH_FAILED_COMMENTS = "FETCH_FAILED_COMMENTS";
export const FETCH_SEND_COMMENTS = "FETCH_SEND_COMMENTS";



// Pour les formations
export const FETCH_SUCCESS_FORMATIONS = "FETCH_SUCCESS_FORMATIONS";
export const FETCH_FAILED_FORMATIONS = "FETCH_FAILED_FORMATIONS";
export const FETCH_SEND_FORMATIONS = "FETCH_SEND_FORMATIONS";

