
export  const  baseurl = {
    url: process.env.REACT_APP_BASE_URL,
    TypeToken: process.env.REACT_APP_TYPE_TOKEN,
    token: process.env.REACT_APP_TOKEN,

    cloudinaryUploadPreset : "preset_dt6ammifo", // Remplacez par votre preset Cloudinary
    cloudinaryCloudName : "dt6ammifo", // Remplacez par votre Cloudinary Cloud Name
}



