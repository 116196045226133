

export const app_bg = {
  bg_blue: "bg-blue-900"
}

export const  profilePictureDefault=""

export const ApiKey = {
  tiny: {
    path: "jdqz7mahdgrp1n38s1ojhs5r76o9fsfahu54klimvo89k367"
  }
}

export const queryCahe = {
  annonces: "annonces",
  annonceDetail: "annonces",
  offreDetail: "annonces",
  offre: "offres",
  profile: {
    candidat: {
      offres: "offreCandidat",
      annonces: "annoncesCandidat",
      profile: "candidatProfile"
    },
    employeur: {
      offres: "employeurOffres",
      annonces: "employeurAnnounces",
      profile: "employeurProfile"
    },
    commun: "commnun"
  },

}


export const IconPack = <svg
  class="w-6 h-6 align-middle"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  stroke="currentColor"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
>
  <path
    d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
  ></path>
  <polyline
    points="22 4 12 14.01 9 11.01"
  ></polyline>
</svg>




export const packsItemsList = [
  {
    pack: "SILVER",
    titre: "Pack STARTER",
    description: "Accédez à des offres d'emploi exclusives pour les étudiants.",
    prix: "1000 F",
    avantages: [
      "Accès illimité aux offres d'emploi",
      "Mises à jour en temps réel",
      "Email et sms de confirmation",
      "Support client dédié",
    ],
  },
  {
    titre: "Pack Premium",
    pack: "GOLD",
    description: "Démarquez-vous avec notre pack premium pour étudiants.",
    prix: "2000 F",
    avantages: [
      "Toutes les fonctionnalités du pack Étudiant",
      "Profil en vedette pour les employeurs",
      "Chat direct avec le recruteur",
      "Accès à des formations exclusives",
      "Coaching ",
    ],
  },
  {
    titre: "Pack VIP",
    pack: "DIAMOND",
    description: "Le summum de nos offres pour votre succès professionnel.",
    prix: "5000 F",
    avantages: [
      "Alertes Emplois",
      "Coaching professionnel personnalisé",
      "Chat direct avec le recruteur",
      "Réseau exclusif de recruteurs",
      "Bourse d'études à l'étranger",
      "Coaching  & formation",

    ],
  },
];



export const packsItemsList2 = [
  {
    pack: "SILVER",
    titre: "Pack STARTER",
    description: "Accédez à des offres d'emploi exclusives pour les étudiants.",
    prix: "1000 F",
    avantages: [
      "Offre d'emplois Illimintés",
      "Mises à jour en temps réel",
      "Support client dédié",
      "Discussions avec les candidats",
    ],
  },
  {
    titre: "Pack Premium",
    pack: "GOLD",
    description: "Démarquez-vous avec notre pack premium pour étudiants.",
    prix: "2000 F",
    avantages: [
      "Profil en vedette des meilleurs candidats",
      "Discussions avec les candidats",
      "30 emails et sms",
      "Coaching & formations",
    ],
  },
  {
    titre: "Pack VIP",
    pack: "DIAMOND",
    description: "Le summum de nos offres pour votre succès professionnel.",
    prix: "5000 F",
    avantages: [
      "Toutes les fonctionnalités les fonctionnalités",
      "Coaching professionnel personnalisé",
      "Discussions avec les candidats",
      "Réseau exclusif de recruteurs",
      "Bourse d'études à l'étranger",
      "Sms et email illimités",
      "Mise en vedette de votre offre d'emplois",
    ],
  },
];



export const statusPACKS = [
  "SILVER", "GOLD", "DIAMOND",]